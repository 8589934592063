import React from "react"
import Layout from "./layout"
import HoursOfOpsTable from "../components/houres-of-op-table"
import ContactForm from "../components/contact-form"
const ContactUsPage = () => {


    return (

        <Layout>
            <div id="main">

                <div className="container">
                    <section id="primary" className="content-full-width">
                        <div className="dt-sc-hr-invisible-small  "></div>
                        <h1 className="hr-title dt-page-title">		<span>			Contact Us		</span>	</h1>

                        <div id="post-253" className="post-253 page type-page status-publish hentry">
                            <div className="column dt-sc-two-third  first">
                                <h3>Directions</h3>
                                <p className="dt-sc-contact-info address"><i className="fa fa-pagelines"></i><span>25 Cherry Crest Dr, Unit #7, Brampton, ON L6P 3W4</span></p>
                                <div>
                                    <a href="http://maps.google.com/?daddr=Cherrycrest+Drive%2C+Brampton%2C+ON+L6P%2C+Canada" target="_blank" rel="noopener noreferrer">
                                        Get Directions
                                        <br />
                                        <img className="ls-l"
                                            src="/wp-content/uploads/2016/10/google-map-address.jpg" alt="Get Directions" />
                                    </a>
                                    {/* <iframe width="600" height="450"  loading="lazy" allowfullscreen
                                
                                src="https://maps.app.goo.gl/Zw1sznuCA5aWeDYG7"></iframe> */}

                                </div>
                                <h3>Get in Touch</h3>
                                <ContactForm />
                            </div>
                            <div className="column dt-sc-one-third  ">
                                <h3>Our Store</h3>
                                <img className="ls-l"
                                    src="/wp-content/uploads/2016/10/Athena-Salon-Front-View.jpg" alt="" />

                                <h3>Book An Appointment</h3>
                                <div className="dt-sc-appointment"><p className="dt-sc-contact-info"><i className="fa fa-phone"></i>Phone : <span><a href="tel:+19057943822">+1 905 794 3822</a></span></p></div>
                                <p>All of our services are provided by trained and experience staff, to ensure you and your party are accommodated please make appointments as necessary. Please ensure to cancel or reschedule at-least 4-5 hours in advance. Prices are subjected to change without notice. All prices do not include taxes.</p>
                                <p>&nbsp;</p>
                                <div className="dt-sc-titled-box  ">
                                    <h6 className="dt-sc-titled-box-title"> Hours of Operations</h6>
                                    <div className="dt-sc-titled-box-content">
                                        <HoursOfOpsTable />
                                    </div>
                                </div>

                            </div>
                            <div className="social-bookmark"></div>
                            <div className="social-share"></div>
                        </div>


                    </section>
                </div>
            </div>

        </Layout >
    )
}

export default ContactUsPage
